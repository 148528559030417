import initObserver from './general/observer';
import MoveTo from 'moveto';
export default function (activationSource) {
    const sectionPicker = document.querySelector('.detail-section-picker');
    const scrollSentinel = document.querySelector('.picker-sentinel');
    const sections = document.querySelectorAll(".picker-section:has(*)");

    if ((!sectionPicker) || (sections.length === 0)) return;

    activateScroll(sectionPicker, scrollSentinel);
    setHeadlines(sections, sectionPicker);
}

function activateScroll(element, sentinel) {

    if (element) {
        initObserver(sentinel, function () {
            element.classList.add('stuck');
        }, function () {
            element.classList.remove('stuck');
        });
    }
}
function setHeadlines(sections, sectionPicker) {
    sections.forEach((section, index) => {

        const sectionHeadline = section.querySelector(".headline");
        const headlineClone = sectionHeadline.cloneNode(true);

        sectionPicker.appendChild(headlineClone);

        let scrollTarget = sectionHeadline;

        if (index === 0) { // first headline
            sectionHeadline.style.display = "none";
            scrollTarget = document.querySelector(".detail-main-info");
        }
        headlineClone.addEventListener('click', function () {
            const moveTo = new MoveTo({ tolerance: window.scrollOffset });
            moveTo.move(scrollTarget);

        });

        if (headlineClone.classList.contains("question-headline")) {
            headlineClone.innerText = "Zadaj pytanie";
        }

        initActiveHighlighting(headlineClone, section);
    });
}
function initActiveHighlighting(headline, section) {
    if (section) {
        const sentinel = section.querySelector(".section-sentinel");
        initObserver(sentinel, function () {

            headline.classList.remove('active');
            if (!headline.parentNode.querySelector(".active")) { // if none are highlighted highlights the first one
                headline.parentNode.querySelector(".headline:first-of-type").classList.add("active");
            }

        }, function () {

            const previousActive = headline.parentNode.querySelector(".active");
            if (previousActive) {
                previousActive.classList.remove("active");
            }

            headline.classList.add('active');

        });
    }
}