import { Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Navigation, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 4000
                },
                lazy: true,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next.main-slider-button',
                    prevEl: '.swiper-button-prev.main-slider-button'
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');
                        
                        if (slideImg.hasAttribute('src')) return;

                        currentSlide.classList.add('loaded');
                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    }
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-wrapper.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider1.update();
            });
        });
    }, false);
}