import { on } from 'delegated-events';

import { bannersReload } from './banners-product-card';
import { findSiblings, isVisible, showLoader, hideLoader, conditionalContentSet } from '../utils/utils';
import questionForm from '../question-form';
export default function () {
    function priceFormatter(value) {

        if (value) {
            return value.toFixed(2).toString().replace('.',',');
        }
        return value;
    }

    function gatherAndSend(caller) {

        showLoader();

        const selectedFeatureArray = [];
        const scope = caller.closest('[data-scope="addcart"]');

        scope.querySelectorAll('dd ul').forEach(ul => {
            ul.querySelectorAll('li').forEach(li => {
                if (li.classList.contains('selected')) {
                    selectedFeatureArray.push(li.getAttribute('data-dictid'));
                }
            });
        });

        fetch('cartaction/FetchOptions', {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify({
                request: {
                    dicts: selectedFeatureArray,
                    pid: scope.querySelector('[data-pid]').getAttribute('data-pid')
                }
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then(data => {
            
                const optionItem = scope.querySelector('[data-optionid]');

                if (data.OptionId) {
                    optionItem.setAttribute('data-optionid', data.OptionId);
                } else {
                    optionItem.setAttribute('data-optionid', -1);
                }

                if (data.ProductVariantUrl) {
                    window.history.pushState(null, null, data.ProductVariantUrl);
                }

                if (data.HtmlResponse) {
                    scope.querySelector('[data-role="option-view"]').innerHTML = data.HtmlResponse;
                }

                conditionalContentSet(document, '[data-role="sku"]', data.SKU);
                conditionalContentSet(document, '[data-role="ean"]', data.EAN);

                if (data.Prices) {
                    var pricesItem = data.Prices;

                    conditionalContentSet(scope, '[data-role="product-price"]', priceFormatter(pricesItem.PriceGross));

                    const omnibusPrice = scope.querySelector('[data-role="omnibus-lowest-price"]');
                    if (omnibusPrice) {
                        omnibusPrice.innerHTML = '';

                        if (pricesItem.OldPriceGross) {
                            omnibusPrice.innerHTML = pricesItem.OmnibusInfo;
                        }
                    }

                    conditionalContentSet(scope, '[data-role="product-discount-percentage"]', pricesItem.PercentDiscount);
                    conditionalContentSet(scope, '.promo-text', pricesItem.PercentDiscount);
                    conditionalContentSet(scope, '[data-role="product-oldprice"]', priceFormatter(pricesItem.OldPriceGross));
                }

                if (data.ProductState) {
                    const state = data.ProductState;
                    const availName = scope.querySelector('[data-role="avail-name"]');

                    if (availName) {
                        availName.innerHTML = state.Name;
                        availName.style.color = state.HexHtmlColor;
                    }

                    conditionalContentSet(document, '[data-role="avail-desc"]', state.Description);
                    conditionalContentSet(document, '[data-role="avail-time"]', state.ShippingTime);
                }

                if (data.Images) {
                    bannersReload(data.Images);
                }

                const buyButton = scope.querySelector('.buy-button');

                if (data.CartEnabled) {
                    scope.classList.remove('cart-disabled');
                    buyButton.removeAttribute('disabled');
                } else {
                    scope.classList.add('cart-disabled');
                    buyButton.setAttribute('disabled', true);
                }

                questionForm();

                hideLoader();
            })
            .catch(error => {
                console.log(error);
                hideLoader();
            });
    }

    // Wybór wariantu
    on('click', '.detail-options li:not(.selected)', function () {
        const liSiblings = findSiblings(this);

        liSiblings.forEach(li => li.classList.remove('selected'));
        this.classList.add('selected');

        gatherAndSend(this);
    });

    on('click', '.detail-options li.selected', function () {
        const list = document.querySelector('.detail-options dd.list-visible');

        if (list) {
            list.classList.remove('list-visible');
        }
    });
}