import showPopup from './modals';
import initRecaptcha from './recaptcha';
import { showLoader, hideLoader } from './utils/utils';
import detailSectionPicker from './detail-section-picker';

const sendQuestion = (form) => {
    const fields = [...form.elements].filter(elem => {
        return elem.type !== 'submit'
    }).map(({ name, type, value }) => {
        return {
            name,
            type,
            value
        };
    });

    if (window.currentModalInstance) {
        window.currentModalInstance.close();
    }

    const recaptchaItem = form.querySelector('.g-recaptcha-response');

    fetch('api/frontend/SendQuestionForm', {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
            fields,
            recaptcha: recaptchaItem ? recaptchaItem.value : ''
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
    .then(response => response.json())
        .then(data => {
            hideLoader();
            console.log(data)
            showPopup({ body: data.message })

            form.querySelectorAll('.field-wrapper').forEach(wrapper => {
                wrapper.classList.remove('valid');
                wrapper.classList.remove('filled');
            });

            if (window.grecaptcha) {
                const grecaptchaInstances = document.querySelectorAll('.g-recaptcha-response');

                grecaptchaInstances.forEach((instance, index) => {
                    if (recaptchaItem === instance) {
                        window.grecaptcha.reset(index);
                    }
                });
            }
        
            form.reset();

            form.removeAttribute("data-submitting");
    });
}

export default function () {
    const formActivators = document.querySelectorAll('[data-modal-form]');
    if (formActivators.length > 0) {

        formActivators.forEach(activator => {

            const type = +activator.getAttribute('data-modal-form');
            const id = activator.getAttribute('data-id') ? +activator.getAttribute('data-id') : null;

            const fetchUrl = 'api/frontend/FetchQuestionFormTemplate';
            const fetchSettings = {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({
                    type,
                    id
                }),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }

            if (activator === document.querySelector('.detail-form-wrapper')) {  // if activator == wrapper for static form

                fetch(fetchUrl, fetchSettings)
                .then(response => response.json())
                .then(data => {

                    activator.innerHTML = data.htmlTemplate;

                    import('./front-validation').then(module => module.default([document.querySelector('.detail-form-wrapper [data-run-validator]')]));
                    initRecaptcha(activator.querySelector('.captcha-wrapper'));

                    detailSectionPicker();
                });

            } else { // if activator == button that shows form in popup
                activator.addEventListener('click', e => {

                    e.preventDefault();
                    showLoader();

                    fetch(fetchUrl, fetchSettings)
                    .then(response => response.json())
                    .then(data => {

                        hideLoader();
                        showPopup({
                            header: 'Formularz zapytania',
                            body: data.htmlTemplate,
                            overloadClass: 'form-modal'
                        });

                        import('./front-validation').then(module => module.default([document.querySelector('.goshop-modal [data-run-validator]')]));
                        initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
                    });
                });
            }
        });
    }
}

export { sendQuestion };